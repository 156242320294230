import React from "react"
import UserItemsForm from "./components/UserItemsForm"

function UserItems() {
  return (
    <div>
      <h2>User Conditions and Goals</h2>
      <p>
        Anything sent here will be logged and added to the build roadmap if it's
        achievable.
      </p>
      <UserItemsForm />
    </div>
  )
}

export default UserItems
