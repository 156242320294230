import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./EthicsForm.css"

function EthicsForm() {
  const { currentUser } = useAuth()
  const [prompts, setPrompts] = useState([])
  const [currentPrompt, setCurrentPrompt] = useState({
    ethics_prompt_system: "",
    ethics_prompt_user: "",
    ethics_prompt_creator: currentUser.username,
    ethics_prompt_top_p: "",
    ethics_prompt_temperature: "",
  })

  useEffect(() => {
    fetchPrompts()
  }, [])

  const fetchPrompts = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/ethics-prompts")
      .then((response) => {
        setPrompts(response.data)
        if (response.data.length > 0) {
          setCurrentPrompt(response.data[0])
        }
      })
      .catch((error) => {
        console.error("Error fetching prompts:", error)
      })
  }

  const handlePromptClick = (prompt) => {
    setCurrentPrompt(prompt)
  }

  const handleInputChange = (e) => {
    setCurrentPrompt({
      ...currentPrompt,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = () => {
    if (currentPrompt.ethics_prompt_id) {
      // Update existing prompt
      axios
        .put(
          `https://api.syfthealth.app/sandbox/ethics-prompts/${currentPrompt.ethics_prompt_id}`,
          currentPrompt
        )
        .then(() => {
          fetchPrompts() // Refresh the list after updating
        })
        .catch((error) => {
          console.error("Error updating prompt:", error)
        })
    } else {
      // Add new prompt
      axios
        .post(
          "https://api.syfthealth.app/sandbox/ethics-prompts",
          currentPrompt
        )
        .then(() => {
          fetchPrompts() // Refresh the list after adding
        })
        .catch((error) => {
          console.error("Error adding prompt:", error)
        })
    }
  }

  return (
    <div className="container">
      <div className="previous-prompts">
        <strong style={{ display: "block", marginBottom: "10px" }}>
          Previous Versions
        </strong>
        {prompts.map((prompt) => (
          <div
            key={prompt.ethics_prompt_id}
            onClick={() => handlePromptClick(prompt)}
            className="prompt-item"
          >
            <span className="prompt-date">
              {new Date(prompt.ethics_prompt_created).toLocaleString()}
            </span>
          </div>
        ))}
      </div>
      <div className="current-prompt">
        <label htmlFor="ethics_prompt_system">System Prompt</label>
        <textarea
          id="ethics_prompt_system"
          name="ethics_prompt_system"
          value={currentPrompt.ethics_prompt_system}
          onChange={handleInputChange}
          className="prompt"
          placeholder="System Prompt"
        />

        <label htmlFor="ethics_prompt_user">User Prompt</label>
        <textarea
          id="ethics_prompt_user"
          name="ethics_prompt_user"
          value={currentPrompt.ethics_prompt_user}
          onChange={handleInputChange}
          placeholder="User Prompt"
          className="prompt"
        />

        <label htmlFor="ethics_prompt_top_p">Top P Value</label>
        <input
          id="ethics_prompt_top_p"
          name="ethics_prompt_top_p"
          value={currentPrompt.ethics_prompt_top_p}
          onChange={handleInputChange}
          placeholder="Top P Value"
          type="number"
        />

        <label htmlFor="ethics_prompt_temperature">Temperature</label>
        <input
          id="ethics_prompt_temperature"
          name="ethics_prompt_temperature"
          value={currentPrompt.ethics_prompt_temperature}
          onChange={handleInputChange}
          placeholder="Temperature"
          type="number"
        />
        <button onClick={handleSubmit}>Save</button>
      </div>
    </div>
  )
}

export default EthicsForm
