import React, { useState } from "react"
import "./Login.css" // Import the CSS
import logo from "./logo-white.svg" // Adjust the path to where your SVG is located
import { useAuth } from "./authContext" // Adjust the path to where your authContext.js is located
import { useNavigate } from "react-router-dom"
// import { ACCESS_LEVELS } from "./AccessLevels"

const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await login(username, password) // Await the response from the login function

    if (response.success) {
      console.log("Login successful!")
      console.log("response.route", response.route)
      navigate(response.route)
    } else {
      console.error(response.error)
      // Display an error message to the user or handle the error accordingly
    }
  }

  return (
    <div className="outer-container">
      <div className="form-container">
        {" "}
        {/* Apply the CSS class */}
        {/* <h2>Login</h2> */}
        <img src={logo} alt="Login" className="login-logo" />
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username" // Placeholder for username
            />
          </label>
          <label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password" // Placeholder for password
            />
          </label>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login
