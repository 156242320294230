import React from "react"
import SyftForm from "./components/SyftForm"

function Syft() {
  return (
    <div>
      <h2>Syft Models</h2>
      <p>This interface connects directly to the Syft API.</p>
      <SyftForm />
    </div>
  )
}

export default Syft
