import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./RequestForm.css"

function RequestForm() {
  const { currentUser } = useAuth()
  const [requests, setRequests] = useState([])

  useEffect(() => {
    axios
      .get("https://api.syfthealth.app/sandbox/feedback-requests")
      .then((response) => {
        setRequests(response.data)
      })
      .catch((error) => {
        console.error("Error fetching requests:", error)
      })
  }, [])

  const handleStatusChange = (requestId, status) => {
    axios
      .put(
        `https://api.syfthealth.app/sandbox/feedback-requests/${requestId}`,
        { feature_request_status: status }
      )
      .then(() => {
        const updatedRequests = requests.map((request) => {
          if (request.id === requestId) {
            request.feature_request_status = status
          }
          return request
        })
        setRequests(updatedRequests)
      })
      .catch((error) => {
        console.error("Error updating status:", error)
      })
  }

  return (
    <div>
      {requests.length === 0 ? (
        <p>No feedback requests available.</p>
      ) : (
        <div className="requestContainer">
          {requests.map((request) => (
            <div key={request.id} className="requestRow">
              {/* <div className="requestItem">{request.portal_users_email}</div> */}
              <div className="requestItem">{request.portal_users_username}</div>
              <div className="requestItemDesc">
                {request.feature_description}
              </div>
              <div className="requestItem">{request.priority}</div>
              <div className="requestItem">
                {request.feature_request_status}
              </div>
              <div className="requestItem">
                <select
                  value={request.feature_request_status}
                  onChange={(e) =>
                    handleStatusChange(request.id, e.target.value)
                  }
                >
                  <option value="Pending">Pending</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default RequestForm
