import React from "react"
import UserMealStats from "./components/UserMealStats"

function UserMeals() {
  return (
    <div>
      <h2>User Stats</h2>
      <UserMealStats />
    </div>
  )
}

export default UserMeals
