//Demo.js
import React, { useState, useRef, useEffect } from "react"
import "./Messaging.css"
import NutritionTable from "./components/NutritionTable"
import { useAuth } from "./authContext"

const Message = ({ text, sender, type, image }) => {
  return (
    <div className={`message ${type}`}>
      {type.includes("bot") ? (
        <img src={image} alt={`${sender} avatar`} className="avatar" />
      ) : null}
      <div className={`bubble ${type}`}>
        {type === "syftbot" ? <NutritionTable mealData={text} /> : text}
      </div>
    </div>
  )
}

const MessagingInterface = () => {
  const { currentUser } = useAuth()
  //   const [messages, setMessages] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [responses, setResponses] = useState([""]) // To store server responses
  const [input, setInput] = useState("")
  const [socket, setSocket] = useState(null)
  const messagesEndRef = useRef(null)
  const [messages, setMessages] = useState([
    // ... Initial messages here
  ])

  const [mealData, setMealData] = useState(null) // Meal data state
  const [userData, setUserData] = useState(null) // Meal data state
  const [response, setResponse] = useState("") // To store server responses
  const [actions, setActions] = useState({
    logMeal: false,
    adjustMeal: false,
    userData: false,
    conversation: false,
  }) // Actions state

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [messages])

  const sendMessage = (ws, messages) => {
    const dataToSend = {
      userID: currentUser.id, // or simply 'userId' in ES6 shorthand property
      messages: messages,
    }
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(dataToSend)) // Send the entire messages array as a JSON string
    } else {
      console.error("WebSocket is not open. Cannot send message.")
    }
  }

  // Initialize WebSocket connection
  const initializeWebSocket = () => {
    const ws = new WebSocket("wss://api.syfthealth.app/synfony/")
    ws.onopen = () => console.log("Connected to the WebSocket")
    ws.onmessage = (event) => {
      try {
        // Try parsing the message as JSON
        // console.log(event.data)
        const jsonData = JSON.parse(event.data)

        // console.log(JSON.stringify(jsonData))

        // Check if it has specific keys (like "mealData")
        if (jsonData.actions) {
          console.log(jsonData.actions)
        }

        if (jsonData.mealData) {
          // Add the data to the appropriate state for processing JSON
          console.log(jsonData.mealData)
          console.log(jsonData.response)
          setMealData(jsonData.mealData) // assuming you have a state setter called setMealData
          setResponse(jsonData.response)
          setIsSending(false)
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: jsonData.mealData,
              sender: "Syft",
              type: "syftbot",
              image:
                "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
            },
          ])

          console.log(messages)
        }
        if (jsonData.logMeal) {
          console.log(jsonData)
          setActions(jsonData)
        }
        if (jsonData.userData) {
          console.log("User Data")
          console.log(jsonData.userData)
          setUserData(jsonData.userData)
        }
        if (jsonData.response) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: jsonData.response,
              sender: "Syft",
              type: "syftbotchat",
              image:
                "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
            },
          ])
        } else {
          console.log([event.data])
          setResponses([event.data])
        }
      } catch (error) {
        // If there's an error in parsing, it's a plain string
        setResponses([event.data])
      }
    }
    ws.onclose = () => {
      console.log("Disconnected from the WebSocket")
    }
    setSocket(ws)
    return ws
  }
  // useEffect(() => {
  //   const ws = initializeWebSocket()

  //   // Cleanup WebSocket connection
  //   return () => {
  //     ws.close()
  //   }
  // }, [])

  const handleSend = () => {
    if (input) {
      const updatedMessages = [
        ...messages,
        { text: input, sender: "user", type: "user", image: "" },
      ]

      if (!socket || socket.readyState !== WebSocket.OPEN) {
        const newSocket = initializeWebSocket()
        console.log(updatedMessages)
        newSocket.onopen = () => {
          sendMessage(newSocket, updatedMessages) // Send the updated messages using the local variable
        }
        setSocket(newSocket)
      } else {
        sendMessage(socket, updatedMessages) // Send the updated messages using the local variable
      }

      setMessages(updatedMessages) // Update the state after sending the message
      setInput("")
    }
  }

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages])

  return (
    <div className="messaging-container">
      <div className="responses">{responses}</div>
      <div className="messages">
        {messages.map((msg, index) => (
          <Message key={index} {...msg} />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="input-container">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  )
}

export default MessagingInterface
