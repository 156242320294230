import React, { useState } from "react"
import { useAuth } from "../authContext"

function FeedbackForm() {
  const { currentUser } = useAuth()
  const [featureRequest, setFeatureRequest] = useState("")
  const [priority, setPriority] = useState("Medium")
  const [apiResponse, setApiResponse] = useState("")
  const [isSending, setIsSending] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSending(true)
    const formData = {
      portal_users_id: currentUser.id,
      featureRequest,
      priority,
    }

    // Adjust this to point to the correct API endpoint
    try {
      const response = await fetch(
        "https://api.syfthealth.app/sandbox/featureRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
      const data = await response.text()
      console.log(data)
      setApiResponse(data)
      setFeatureRequest("")
    } catch (error) {
      console.error("There was an error submitting the form:", error)
    }
    setIsSending(false)
  }

  // Styles
  const formStyles = {}

  const textAreaStyles = {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    height: "100px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }
  const inputStyles = {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#aaa" : "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer",
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div>
          <label>
            Feature Description:
            <textarea
              value={featureRequest}
              onChange={(e) => setFeatureRequest(e.target.value)}
              style={textAreaStyles}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Priority:
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              style={inputStyles}
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </label>
        </div>
        <div>
          <button type="submit" style={buttonStyles} disabled={isSending}>
            Send
          </button>
        </div>
      </form>
      <div>{apiResponse}</div>
    </div>
  )
}

export default FeedbackForm
