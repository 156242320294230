//Menu.js
import { NavLink } from "react-router-dom"

const Menu = ({ items, currentUserAccessLevel }) => (
  <nav>
    <ul>
      {items
        .filter((item) =>
          item.allowedAccessLevels.includes(currentUserAccessLevel)
        )
        .map((item) => (
          <li key={item.id}>
            <NavLink
              to={item.path}
              activestyle={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
    </ul>
  </nav>
)

export default Menu
