import React, { useState, useEffect } from "react"
import "./Dashboard.css"
import { ReactComponent as SyftIcon } from "./syft-icon.svg"
import { ReactComponent as OpenAIIcon } from "./openai-icon.svg"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./authContext"

function Dashboard() {
  const [endpointStatuses, setEndpointStatuses] = useState({})
  const [modelStats, setModelStats] = useState([])
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  useEffect(() => {
    // Redirect based on access level
    if (currentUser && currentUser.access > 1) {
      navigate("/demo") // Adjust the route accordingly
    }

    // ... rest of your useEffect code
  }, [currentUser, navigate])

  useEffect(() => {
    // Fetch endpoint statuses
    async function fetchEndpointStatuses() {
      try {
        const response = await fetch(
          "https://api.syfthealth.app/sandbox/model-status"
        )
        const data = await response.json()
        setEndpointStatuses(data)
      } catch (error) {
        console.error("Error fetching endpoint statuses:", error)
      }
    }

    // Fetch model stats
    async function fetchModelStats() {
      try {
        const response = await fetch(
          "https://api.syfthealth.app/sandbox/model-stats"
        )
        const statsData = await response.json()
        setModelStats(statsData)
      } catch (error) {
        console.error("Error fetching model stats:", error)
      }
    }

    fetchEndpointStatuses()
    fetchModelStats()
  }, [])

  function getTotalResponsesForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    return stats ? stats.total_responses : null
  }

  function getStatusDisplayValue(status) {
    return status === "UNAVAILABLE" ? "Paused" : status
  }

  function handleModelClick(endpoint) {
    if (endpointStatuses[endpoint] === "InService") {
      // Navigate to /syft route and pass the endpoint as state
      navigate("/syft", { state: { theModel: endpoint } })
    }
  }

  function decodeEndpointName(endpoint) {
    let humanReadable = ""

    // Check for 'Xb' pattern and replace with "X billion parameters"
    const billionMatch = endpoint.match(/(\d+)b/)
    if (billionMatch) {
      humanReadable += `${billionMatch[1]}b params`
    }

    // Check for '-f' pattern and append " (Chat)"
    if (endpoint.includes("-f")) {
      humanReadable += " (Chat)"
    }

    return humanReadable || endpoint
  }

  function getAverageComputeForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    return stats && stats.avg_compute_per_word
      ? parseFloat(stats.avg_compute_per_word).toFixed(2)
      : null
  }

  function createMergedList() {
    let mergedList = { ...endpointStatuses }
    modelStats.forEach((stat) => {
      if (!mergedList[stat.prompt_response_model]) {
        mergedList[stat.prompt_response_model] = "N/A"
      }
    })
    return mergedList
  }

  function isMergedModel(model) {
    return endpointStatuses[model] === undefined
  }

  const mergedStatuses = createMergedList()

  function getMaxTimeTakenForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    return stats ? stats.max_time_taken : null
  }

  function getMaxWordsForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    return stats ? stats.max_words : null
  }

  function getAverageWordCountForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    return stats && stats.avg_word_count
      ? parseFloat(stats.avg_word_count).toFixed(2)
      : null
  }
  function getAverageTimeForModel(model) {
    const stats = modelStats.find(
      (stat) => stat.prompt_response_model === model
    )
    if (stats && stats.avg_compute_per_word && stats.avg_word_count) {
      return (
        parseFloat(stats.avg_compute_per_word) *
        parseFloat(stats.avg_word_count)
      ).toFixed(2)
    }
    return null
  }

  return (
    <div style={{ margin: "0 20px" }}>
      <h2>Syft Portal</h2>

      <div>
        <h3>Model Statuses:</h3>
        <ul className="endpoint-list">
          {Object.entries(mergedStatuses).map(([endpoint, status], index) => (
            <li
              key={index}
              className={status.replace(/\s+/g, "")}
              onClick={() => handleModelClick(endpoint)} // Add the click handler
              style={{ cursor: "pointer" }} // Optional: Change cursor on hover to indicate clickability
            >
              <span className="icon">
                {isMergedModel(endpoint) ? <OpenAIIcon /> : <SyftIcon />}
              </span>
              {decodeEndpointName(endpoint)}
              <br />
              <strong>{getStatusDisplayValue(status)}</strong>

              <span className="stats">
                Avg. Sec/Word: {getAverageComputeForModel(endpoint)}
                <br />
                Avg. Time Taken: {getAverageTimeForModel(endpoint)} seconds
                <br />
                Max Time: {getMaxTimeTakenForModel(endpoint)}
                <hr />
                Avg. # Words: {getAverageWordCountForModel(endpoint)}
                <br />
                Max Words: {getMaxWordsForModel(endpoint)}
                <hr />
                No. Responses: {getTotalResponsesForModel(endpoint)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Dashboard
