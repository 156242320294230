import React, { useState, useEffect, useRef } from "react"

const Logs = () => {
  const [logData, setLogData] = useState("")
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("syftSynfonyAPI-out.log") // Adjust as needed
  const logContainerRef = useRef(null)
  const [logFiles, setLogFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState("syftSynfonyAPI-out.log")
  const [logLength, setLogLength] = useState(10000)

  useEffect(() => {
    const fetchLogFiles = async () => {
      try {
        const response = await fetch(
          "https://api.syfthealth.app/sandbox/log-files"
        ) // Adjust API endpoint as needed
        const data = await response.json()
        setLogFiles(data)
        setSelectedFile(data[0]) // Set default file (first one in the list)
      } catch (error) {
        console.error("Error fetching log files:", error)
      }
    }

    fetchLogFiles()
  }, [])

  const fetchLogs = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `https://api.syfthealth.app/sandbox/logs?fileName=${fileName}&length=${logLength}`
      ) // Adjust length as needed
      const data = await response.text()
      setLogData(data)
    } catch (error) {
      console.error("Error fetching logs:", error)
    } finally {
      setLoading(false)
    }
  }
  //   useEffect(() => {
  //     if (logContainerRef.current) {
  //       // Delay the scroll a bit to ensure the DOM has updated
  //       setTimeout(() => {
  //         logContainerRef.current.scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         })
  //       }, 200) // Adjust the delay as needed
  //     }
  //   }, [logData])

  useEffect(() => {
    fetchLogs()
  }, [fileName, logLength])

  const reloadLogs = () => {
    console.log("Reloading logs...")
    fetchLogs()
  }

  const handleFileChange = (event) => {
    const newFileName = event.target.value
    setFileName(newFileName) // Set the file name for fetching logs
    setSelectedFile(newFileName) // Update the selected file for the dropdown
  }

  //   if (loading) return <div>Loading...</div>

  return (
    <div>
      <div>
        <h2>Log Viewer</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            File:
            <select value={selectedFile} onChange={handleFileChange}>
              {logFiles.map((file, index) => (
                <option key={index} value={file}>
                  {file}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginRight: "10px" }}>
            Length:
            <input
              type="number"
              value={logLength}
              onChange={(e) => setLogLength(e.target.value)}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <div style={{ marginRight: "10px" }}>
            <button onClick={reloadLogs}>Reload Logs</button>
          </div>
        </div>
      </div>
      <pre
        ref={logContainerRef}
        style={{
          backgroundColor: "#000",
          fontFamily: "monospace",
          color: "#fff",
          textAlign: "left",
          height: "66vh",
        }}
      >
        {logData}
      </pre>
      {/* Implement pagination or "load more" logic here */}
    </div>
  )
}

export default Logs
