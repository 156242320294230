//Synfony.js
import React from "react"
import LabForm from "./components/LabForm"

function Lab() {
  return (
    <div>
      <h2>Syft AI Tone Lab</h2>
      <LabForm />
    </div>
  )
}

export default Lab
