// PrivateRoute.js
import { useAuth } from "./authContext"
import { Outlet, Navigate } from "react-router-dom"
import React from "react"

function PrivateRoute({ allowedAccessLevels }) {
  let { currentUser } = useAuth()

  if (currentUser && allowedAccessLevels.includes(currentUser.access)) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

// function PrivateRoute(props) {
//   let { currentUser } = useAuth()

//   const [forceUpdate, setForceUpdate] = useState(false)
//   useEffect(() => {
//     setForceUpdate(!forceUpdate)
//   }, [currentUser])

//   const canAccessRoute = (allowedAccessLevels) => {
//     return currentUser && allowedAccessLevels.includes(currentUser.access)
//   }

//   // Check access level
//   if (props.accessLevel && !canAccessRoute(props.accessLevel)) {
//     return <Navigate to="/" /> // Redirect to home or some other page if not authorized
//   }

//   if (currentUser) {
//     return <Outlet />
//   } else {
//     return <Navigate to="/login" />
//   }
// }

export default PrivateRoute
