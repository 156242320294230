import React, { useState, useEffect } from "react"
import { useAuth } from "../authContext"

function OpenAIForm() {
  const { currentUser } = useAuth()
  const [startMeal, setStartMeal] = useState(0)
  const [numMeals, setNumMeals] = useState(1)
  const [theModel, setTheModel] = useState("gpt-4")
  const [thePrompt, setThePrompt] = useState("")
  const [apiResponse, setApiResponse] = useState("")
  const [timer, setTimer] = useState(0)
  const [isSending, setIsSending] = useState(false)
  const [systemPrompt, setSystemPrompt] = useState("")

  useEffect(() => {
    let timerId
    if (isSending) {
      timerId = setInterval(() => {
        setTimer((prevTime) => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(timerId)
      //   setTimer(0)
    }
    return () => clearInterval(timerId) // Clear interval when component unmounts
  }, [isSending])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSending(true)
    setTimer(0)
    const formData = {
      userId: currentUser.id, // Pass user's ID
      startMeal,
      numMeals,
      theModel,
      thePrompt,
      systemPrompt,
    }

    try {
      const response = await fetch(
        "https://api.syfthealth.app/sandbox/testPrompt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
      const data = await response.json() //.json()
      console.log(data)
      //   setApiResponse(JSON.stringify(data, null, 2)) // Pretty print the JSON data
      setApiResponse(data) // Pretty print the JSON data
    } catch (error) {
      console.error("There was an error submitting the form:", error)
    }
    setIsSending(false)
  }

  const formStyles = {
    // boxSizing: "border-box",
    // padding: "20px",
    // borderRadius: "5px",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    // margin: "40px auto",
  }

  const inputStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }
  const promptStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#eee" : "rgba(0,0,0,0.3)", // Change color when sending
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer", // Change cursor when sending
  }

  const leftColumn = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: "0 0 200px", // left column will be 200px wide
    flexBasis: "200px", // IE 10-11 fallback
    width: "200px", // fixed width for left column
  }

  const rightColumn = {
    padding: "0 0 0 20px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: 1, // make right column take up the remaining space
  }

  const containerStyles = {
    fontSize: "12px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    width: "100%", // full width
    display: "flex", // set display to flex
    flexDirection: "row", // columns next to each other
    "@media (maxWidth: 768px)": {
      // media query for viewports less than 768px wide
      flexDirection: "column", // stack columns on top of each other
    },
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div style={containerStyles} className="input-container">
          <div style={leftColumn}>
            <div>
              <label>
                Model:
                <select
                  value={theModel}
                  onChange={(e) => setTheModel(e.target.value)}
                  style={inputStyles}
                >
                  <option value="gpt-3.5-turbo-instruct">
                    GTP-3.5 Instruct
                  </option>
                  <option value="gpt-3.5-turbo">GTP-3.5</option>
                  <option value="gpt-3.5-turbo-16k">
                    GPT-3.5 (4x context)
                  </option>
                  <option value="gpt-4">GPT-4</option>
                  <option value="gpt-4-32k">GPT-4 32k</option>
                  <option value="gpt-4-1106-preview">
                    GPT-4 Turbo (preview 128k)
                  </option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Start Meal:
                <input
                  type="number"
                  value={startMeal}
                  onChange={(e) => setStartMeal(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <label>
                Number of Meals:
                <input
                  type="number"
                  value={numMeals}
                  onChange={(e) => setNumMeals(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
          </div>
          <div style={rightColumn}>
            {theModel != "gpt-3.5-turbo-instruct" && (
              <div>
                <label>
                  System Prompt:
                  <textarea
                    value={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    style={{ ...promptStyles, height: "100px" }}
                  />
                </label>
              </div>
            )}
            <div>
              <label>
                Prompt:
                <textarea
                  value={thePrompt}
                  onChange={(e) => setThePrompt(e.target.value)}
                  style={{ ...promptStyles, height: "100px" }}
                />
              </label>
            </div>
            <div>
              <button type="submit" style={buttonStyles} disabled={isSending}>
                Send
              </button>
              {/* {isSending && ( */}
              <span style={{ marginLeft: "10px", whiteSpace: "nowrap" }}>
                Time elapsed: {timer} seconds
              </span>
              {/* )} */}
            </div>
          </div>
        </div>
      </form>
      <div className="response">
        <h3>API Response:</h3>
        {Array.isArray(apiResponse) &&
          apiResponse.map((description, index) => (
            <div key={index} className="meal_description">
              <pre>{description}</pre>
            </div>
          ))}
      </div>
    </div>
  )
}

export default OpenAIForm
