import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./UserItemsForm.css"

function UserItemsForm() {
  const { currentUser } = useAuth()
  const [conditions, setConditions] = useState([])
  const [goals, setGoals] = useState([])
  const [conditionName, setConditionName] = useState("")
  const [goalName, setGoalName] = useState("")

  useEffect(() => {
    fetchConditions()
    fetchGoals()
  }, [])

  const fetchConditions = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/user-conditions")
      .then((response) => {
        setConditions(response.data)
      })
      .catch((error) => {
        console.error("Error fetching conditions:", error)
      })
  }

  const fetchGoals = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/user-goals")
      .then((response) => {
        setGoals(response.data)
      })
      .catch((error) => {
        console.error("Error fetching goals:", error)
      })
  }

  const deleteCondition = (id) => {
    axios
      .delete(`https://api.syfthealth.app/sandbox/user-conditions/${id}`)
      .then(() => {
        setConditions(conditions.filter((c) => c.user_conditions_id !== id))
      })
      .catch((error) => {
        console.error("Error deleting condition:", error)
      })
  }

  const deleteGoal = (id) => {
    axios
      .delete(`https://api.syfthealth.app/sandbox/user-goals/${id}`)
      .then(() => {
        setGoals(goals.filter((g) => g.user_goals_id !== id))
      })
      .catch((error) => {
        console.error("Error deleting goal:", error)
      })
  }
  const addCondition = () => {
    axios
      .post("https://api.syfthealth.app/sandbox/user-conditions", {
        user_conditions_name: conditionName,
      })
      .then(() => {
        fetchConditions() // Refresh the list after adding
        setConditionName("")
      })
      .catch((error) => {
        console.error("Error adding condition:", error)
      })
  }

  const addGoal = () => {
    axios
      .post("https://api.syfthealth.app/sandbox/user-goals", {
        user_goals_name: goalName,
      })
      .then(() => {
        fetchGoals() // Refresh the list after adding
        setGoalName("")
      })
      .catch((error) => {
        console.error("Error adding goal:", error)
      })
  }
  return (
    <div className="container">
      <div className="column">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            addCondition()
          }}
        >
          <input
            value={conditionName}
            onChange={(e) => setConditionName(e.target.value)}
            placeholder="Add a condition"
          />
          <button type="submit">Add</button>
        </form>
        {conditions.length === 0 ? (
          <p>No conditions available.</p>
        ) : (
          <div className="requestContainer">
            {conditions.map((condition) => (
              <div key={condition.user_conditions_id} className="requestRow">
                <div className="requestItem">
                  {condition.user_conditions_name}
                </div>
                <button
                  onClick={() => deleteCondition(condition.user_conditions_id)}
                  className="delete-button"
                >
                  X
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="column">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            addGoal()
          }}
        >
          <input
            value={goalName}
            onChange={(e) => setGoalName(e.target.value)}
            placeholder="Add a goal"
          />
          <button type="submit">Add</button>
        </form>

        {goals.length === 0 ? (
          <p>No goals available.</p>
        ) : (
          <div className="requestContainer">
            {goals.map((goal) => (
              <div key={goal.user_goals_id} className="requestRow">
                <div className="requestItem">{goal.user_goals_name}</div>
                <button
                  onClick={() => deleteGoal(goal.user_goals_id)}
                  className="delete-button"
                >
                  X
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default UserItemsForm
