// .get("https://api.syfthealth.app/sandbox/user-stats")

import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./UserStats.css"

function UserStats() {
  const { currentUser } = useAuth()
  const [users, setUsers] = useState([])
  const [sortedField, setSortedField] = useState(null)
  const [sortDirection, setSortDirection] = useState("asc") // Track sorting direction
  const [displayedUsers, setDisplayedUsers] = useState([]) // Keep sorted users here

  const fetchStats = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/user-stats")
      .then((response) => {
        setUsers(response.data)
        setDisplayedUsers(response.data) // Set initial displayed users
      })
      .catch((error) => {
        console.error("Error fetching conditions:", error)
      })
  }

  useEffect(() => {
    fetchStats()
  }, [])

  // Function to handle sorting
  const sortUsers = (field) => {
    const newSortDirection =
      sortedField === field && sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(newSortDirection)
    setSortedField(field)

    const sortedUsers = [...users].sort((a, b) => {
      if (a[field] < b[field]) return newSortDirection === "asc" ? -1 : 1
      if (a[field] > b[field]) return newSortDirection === "asc" ? 1 : -1
      return 0
    })

    setDisplayedUsers(sortedUsers)
  }

  return (
    <div className="container">
      <div className="column">
        {users.length === 0 ? (
          <p>No users available.</p>
        ) : (
          <div className="requestContainer">
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortUsers("userEmail")}>
                    User Email{" "}
                    {sortedField === "userEmail" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("user_message_count")}>
                    Message Count{" "}
                    {sortedField === "user_message_count" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("user_meal_count")}>
                    Meal Count{" "}
                    {sortedField === "user_meal_count" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("liked_responses")}>
                    Liked Responses{" "}
                    {sortedField === "liked_responses" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("disliked_responses")}>
                    Disliked Responses{" "}
                    {sortedField === "disliked_responses" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("most_recent_activity")}>
                    Most Recent Activity{" "}
                    {sortedField === "most_recent_activity" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("signup_date")}>
                    Signup Date{" "}
                    {sortedField === "signup_date" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("days_since_first_message")}>
                    Days Since First Message{" "}
                    {sortedField === "days_since_first_message" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("days_active")}>
                    Days Active{" "}
                    {sortedField === "days_active" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("days_missed")}>
                    Days Missed{" "}
                    {sortedField === "days_missed" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("missed_days_percentage")}>
                    Missed Days %{" "}
                    {sortedField === "missed_days_percentage" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("avg_messages_per_day")}>
                    Avg Messages/Day{" "}
                    {sortedField === "avg_messages_per_day" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    onClick={() => sortUsers("avg_syft_data_responses_per_day")}
                  >
                    Avg Syft Data Responses/Day{" "}
                    {sortedField === "avg_syft_data_responses_per_day" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("total_messages_per_day")}>
                    Total Messages/Day{" "}
                    {sortedField === "total_messages_per_day" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("weekday_logs")}>
                    Weekday Logs{" "}
                    {sortedField === "weekday_logs" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("weekend_logs")}>
                    Weekend Logs{" "}
                    {sortedField === "weekend_logs" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("avg_weekday_logs")}>
                    Avg Weekday Logs{" "}
                    {sortedField === "avg_weekday_logs" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortUsers("avg_weekend_logs")}>
                    Avg Weekend Logs{" "}
                    {sortedField === "avg_weekend_logs" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayedUsers.map((user) => (
                  <tr key={user.idusers}>
                    <td>{user.userEmail}</td>
                    <td>{user.user_message_count}</td>
                    <td>{user.user_meal_count}</td>
                    <td>{user.liked_responses}</td>
                    <td>{user.disliked_responses}</td>
                    <td>
                      {new Date(user.most_recent_activity).toLocaleDateString()}
                    </td>
                    <td>{new Date(user.signup_date).toLocaleDateString()}</td>
                    <td>{user.days_since_first_message}</td>
                    <td>{user.days_active}</td>
                    <td>{user.days_missed}</td>
                    <td>{user.missed_days_percentage}%</td>
                    <td>{user.avg_messages_per_day}</td>
                    <td>{user.avg_syft_data_responses_per_day}</td>
                    <td>{user.total_messages_per_day}</td>
                    <td>{user.weekday_logs}</td>
                    <td>{user.weekend_logs}</td>
                    <td>{user.avg_weekday_logs}</td>
                    <td>{user.avg_weekend_logs}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserStats
