//LabForm.js
import React, { useState, useEffect } from "react"
import { useAuth } from "../authContext"
import { useLocation } from "react-router-dom"

// import doctorImage from "../images/doctor2.png"
// import marineImage from "../images/marine2.png"
// import partyGirlImage from "../images/partygirl3.png"
// import bollywoodHeroImage from "../images/bollywoodhero2.png"
// import rapperImage from "../images/rapper.png"
// import syftImage from "../images/syft.png"
import "../LabForm.css"
import { parseZone } from "moment"

function LabForm() {
  const { currentUser } = useAuth()
  const [syftPersonalities, setSyftPersonalities] = useState([])
  const [userConditions, setUserConditions] = useState([])
  const [userGoals, setUserGoals] = useState([])

  useEffect(() => {
    fetch("https://api.syfthealth.app/sandbox/syft-personalities")
      .then((response) => response.json())
      .then((data) => {
        // Only keep records with syft_personalities_enabled = 1
        const filteredData = data.filter(
          (item) => item.syft_personalities_enabled === 1
        )

        const transformedData = filteredData.map((item) => {
          return {
            name: item.syft_personalities_name,
            description: item.syft_personalities_description,
            systemPrompt: item.syft_personalities_system_prompt || "",
            userPrompt: item.syft_personalities_user_prompt || "",
            imgSrc: item.syft_personalities_image,
            promptjson: item.syft_personalities_json || "", // Adjust this if your JSON field contains more structured data.
          }
        })
        setSyftPersonalities(transformedData)
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, [])

  useEffect(() => {
    fetch("https://api.syfthealth.app/sandbox/user-conditions")
      .then((response) => response.json())
      .then((data) => {
        const transformedConditions = data.map((item) => {
          return {
            name: item.user_conditions_name,
            description: item.user_conditions_description || "",
            prompt: item.user_conditions_prompt || "",
            json: item.user_conditions_json || "", // Adjust this if your JSON field contains more structured data.
          }
        })
        setUserConditions(transformedConditions)
      })
      .catch((error) => console.error("Error fetching user-conditions:", error))
  }, [])

  useEffect(() => {
    fetch("https://api.syfthealth.app/sandbox/user-goals")
      .then((response) => response.json())
      .then((data) => {
        const transformedGoals = data.map((item) => {
          return {
            name: item.user_goals_name,
            description: item.user_goals_description || "",
            prompt: item.user_goals_prompt || "",
            json: item.user_goals_json || "", // Adjust this if your JSON field contains more structured data.
          }
        })
        setUserGoals(transformedGoals)
      })
      .catch((error) => console.error("Error fetching user-goals:", error))
  }, [])

  const [useEthics, setUseEthics] = useState(false)
  const [safePrompt, setSafePrompt] = useState(false)
  const [selectedPersonality, setSelectedPersonality] = useState("Syft")
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [selectedGoal, setSelectedGoal] = useState(null)
  const [thePrompt, setThePrompt] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [apiResponse, setApiResponse] = useState("")
  const [timer, setTimer] = useState(0)
  const [message, setMessage] = useState("")
  const [isResponseSafe, setIsResponseSafe] = useState(null)
  const location = useLocation()

  async function getHighestInServiceModel() {
    try {
      const response = await fetch(
        "https://api.syfthealth.app/sandbox/model-status"
      )
      const statuses = await response.json()

      // Extract the model names and reverse the order
      const reversedModels = Object.keys(statuses).reverse()

      // Find the first model that is "InService" and ends with "-f"
      const highestModel = reversedModels.find(
        (key) =>
          statuses[key] === "InService" &&
          (key.includes("-f") || key.includes("chat"))
      )

      return highestModel
    } catch (error) {
      console.error("Error fetching and processing endpoint statuses:", error)
      return null // Return null or handle the error as you see fit
    }
  }
  function extractNumberFromString(modelString) {
    const match = modelString.match(/(\d+)b/)
    return match ? parseInt(match[1], 10) : null
  }

  useEffect(() => {
    let timerId
    if (isSending) {
      timerId = setInterval(() => {
        setTimer((prevTime) => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(timerId)
      //   setTimer(0)
    }
    return () => clearInterval(timerId) // Clear interval when component unmounts
  }, [isSending])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setIsSending(true)
      setTimer(0)
      setMessage(" | Finding most powerful model...")
      // Step 1: Get the highest in-service model
      const highestModel = await getHighestInServiceModel()

      // If there's no highest model, handle this case (e.g., show an error to the user, or skip the submission)
      if (!highestModel) {
        console.error("No highest in-service model found.")
        setIsSending(false)
        return // Exit the function
      }
      console.log(highestModel)

      //   setIsSending(true)
      setTimer(0)
      setMessage(
        ` | Using ${extractNumberFromString(highestModel)} billion parameters.`
      )
      const selectedPersonalityObj = syftPersonalities.find(
        (p) => p.name === selectedPersonality
      )

      //   console.log("Safe Prompt: ", safePrompt)
      //   let systemPromptText = ""
      //   if (safePrompt) {
      //     systemPromptText = `In under 80 words, Use slang words and slang phrases to reflect an accent, but don’t reuse the same slang words and phrases in the same message. responding with no dangerous language or harmful suggestions as a ${selectedPersonalityObj.description} ${selectedPersonalityObj.name} to someone with ${selectedCondition} who's trying to ${selectedGoal}. They've just eaten the following.`
      //     systemPromptText = `In under 80 words, Use slang words and slang phrases to reflect an accent, but don’t reuse the same slang words and phrases in the same message. responding with no dangerous language or harmful suggestions as ${selectedPersonalityObj.name} to someone with ${selectedCondition} who's trying to ${selectedGoal}. They've just eaten the following.`
      //   } else {
      //     systemPromptText = `In under 80 words, Use slang words and slang phrases to reflect an accent, but don’t reuse the same slang words and phrases in the same message. respond as a ${selectedPersonalityObj.description} ${selectedPersonalityObj.name} to someone with ${selectedCondition} who's trying to ${selectedGoal}. They've just eaten the following.`
      //     // systemPromptText = `In under 80 words, Use slang words and slang phrases to reflect an accent, but don’t reuse the same slang words and phrases in the same message. respond as (${selectedPersonalityObj.name}) to someone with ${selectedCondition} who's trying to ${selectedGoal}. They've just eaten the following.`
      //     // systemPromptText = `In under 80 words, respond as a ${selectedPersonalityObj.description} (${selectedPersonalityObj.name}) to someone with ${selectedCondition} who's trying to ${selectedGoal}. They've just eaten the following.`
      //   }
      //   let promptText = thePrompt

      // Check if Syft is selected and replace <<PROMPT>> with thePrompt
      //   if (selectedPersonality === "Syft") {
      //     systemPromptText = selectedPersonalityObj.systemPrompt
      //     promptText = selectedPersonalityObj.prompt.replace(
      //       "<<PROMPT>>",
      //       thePrompt
      //     )
      //   }
      let systemPromptText = selectedPersonalityObj.systemPrompt
        .replace("<<PROMPT>>", thePrompt)
        .replace("<<CONDITION>>", selectedCondition)
        .replace("<<GOAL>>", selectedGoal)
      let userPromptText = selectedPersonalityObj.userPrompt
        .replace("<<PROMPT>>", thePrompt)
        .replace("<<CONDITION>>", selectedCondition)
        .replace("<<GOAL>>", selectedGoal)

      console.log("System Prompt: ", systemPromptText)
      console.log("User Prompt: ", userPromptText)
      console.log("Personality: ", selectedPersonalityObj.description)

      // Step 2: Assign it to theModel in formData
      const formData = {
        userId: currentUser.id,
        startMeal: 0,
        numMeals: 1,
        theModel: highestModel,
        thePrompt: userPromptText,
        maxNewTokens: 300,
        topP: 0.2,
        temperature: 1.2,
        returnFullText: false,
        systemPrompt: systemPromptText,
        ethicsCheck: useEthics,
        condition: selectedCondition,
        goal: selectedGoal,
        userMessage: thePrompt,
        personality: selectedPersonalityObj.description,
      }

      // Step 3: Continue with the submission process
      const response = await fetch("https://api.syfthealth.app/sandbox/syft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log(data)
      setApiResponse(data)
    } catch (error) {
      console.error("There was an error submitting the form:", error)
    } finally {
      setIsSending(false)
    }
  }

  const formStyles = {
    // boxSizing: "border-box",
    // padding: "20px",
    // borderRadius: "5px",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    // margin: "40px auto",
  }

  const inputStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }
  const checkboxStyles = { width: "15px", height: "15px" }
  const promptStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#eee" : "rgba(0,0,0,0.3)", // Change color when sending
    color: "#000",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer", // Change cursor when sending
  }

  const leftColumn = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: "0 0 200px", // left column will be 200px wide
    flexBasis: "200px", // IE 10-11 fallback
    width: "200px", // fixed width for left column
    width: "100%", // full width on small screens
  }

  const rightColumn = {
    // padding: "0 0 0 20px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: 1, // make right column take up the remaining space
  }

  const containerStyles = {
    fontSize: "12px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    width: "100%", // full width
    display: "flex", // set display to flex
    flexDirection: "column", // columns next to each other
    justifyContent: "center", // align columns to the top
    "@media (maxWidth: 768px)": {
      // media query for viewports less than 768px wide
      flexDirection: "column", // stack columns on top of each other
    },
  }
  const buttonStyle = {
    margin: "5px",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    color: "#171c36",
    boxSizing: "border-box",
  }

  const personalityButtonStyle = {
    ...buttonStyle,
    // height: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start", // This line ensures vertical alignment to the top
    maxWidth: "170px",
  }

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#007BFF",
    color: "white",
  }
  const selectedPersonalityButtonStyle = {
    ...personalityButtonStyle,
    backgroundColor: "#007BFF",
    color: "white",
  }
  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div
          className="safety-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <div style={{ marginBottom: "0px", alignItems: "center" }}>
            <input
              type="checkbox"
              id="safePrompt"
              checked={safePrompt}
              style={checkboxStyles}
              onChange={() => setSafePrompt(!safePrompt)}
            />
            <label
              htmlFor="safePrompt"
              style={{ marginLeft: "10px", marginBottom: "0px" }}
            >
              Use Safe Prompt
            </label>
          </div> */}
          <div style={{ marginBottom: "0px", alignItems: "center" }}>
            <input
              type="checkbox"
              id="useEthics"
              checked={useEthics}
              style={checkboxStyles}
              onChange={() => setUseEthics(!useEthics)}
            />
            <label
              htmlFor="useEthics"
              style={{ marginLeft: "10px", marginBottom: "0px" }}
            >
              Use Ethics Model
            </label>
          </div>
        </div>
        <div style={containerStyles} className="input-container">
          <div style={leftColumn}>
            <label>Personality:</label>
            <div className={`personality-container`}>
              {syftPersonalities.map((personality) => (
                <button
                  key={personality.name}
                  type="button"
                  onClick={() => setSelectedPersonality(personality.name)}
                  style={
                    selectedPersonality === personality.name
                      ? selectedPersonalityButtonStyle
                      : personalityButtonStyle
                  }
                >
                  <span className="name">{personality.name}</span>
                  <div className="square-container">
                    <img
                      src={
                        "https://images.syfthealth.app/personalityimages/" +
                        personality.imgSrc
                      }
                      alt={personality.name}
                      style={{ width: "100%" }}
                      className="square-image"
                    />
                  </div>
                  <div className="description">{personality.description}</div>
                </button>
              ))}
            </div>

            <label>User Condition:</label>
            <div className={`condition-container`}>
              {userConditions.map((condition) => (
                <button
                  key={condition.name}
                  type="button"
                  onClick={() => setSelectedCondition(condition.name)}
                  style={
                    selectedCondition === condition.name
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                >
                  {condition.name}
                </button>
              ))}
            </div>

            <label>User Goal:</label>
            <div className={`goal-container`}>
              {userGoals.map((goal) => (
                <button
                  key={goal.name}
                  type="button"
                  onClick={() => setSelectedGoal(goal.name)}
                  style={
                    selectedGoal === goal.name
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                >
                  {goal.name}
                </button>
              ))}
            </div>
          </div>

          <div style={rightColumn}>
            <div>
              <label>
                Prompt
                <textarea
                  value={thePrompt}
                  onChange={(e) => setThePrompt(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <button type="submit" style={buttonStyles} disabled={isSending}>
                Send
              </button>
              {/* {isSending && ( */}
              <br />
              <span style={{ marginLeft: "10px", whiteSpace: "nowrap" }}>
                Time elapsed: {timer} seconds {message}
              </span>
              {/* )} */}
            </div>
          </div>
        </div>
      </form>
      <div className="response">
        <h3>API Response:</h3>
        {Array.isArray(apiResponse) &&
          apiResponse.map((description, index) => (
            <div key={index} className="meal_description">
              <pre
                className={
                  description.includes("Harmful content flag: 1")
                    ? "harmful-content"
                    : ""
                }
              >
                {description}
              </pre>
            </div>
          ))}
      </div>
    </div>
  )
}

export default LabForm
