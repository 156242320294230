import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./UserMealStats.css"
import * as XLSX from "xlsx"

// function NutritionTable({ usersData, sortNutrition }) {
//   return (
//     <table>
//       <thead>
//         <tr>
//           <th onClick={() => sortNutrition("id")}>User ID</th>
//           <th onClick={() => sortNutrition("date")}>Date</th>
//           <th onClick={() => sortNutrition("recordCount")}>Record Count</th>
//           {/* <th onClick={() => sortNutrition("calcium.total")}>
//             Calcium Total (mg)
//           </th> */}
//           <th onClick={() => sortNutrition("calcium.averagePercentRDA")}>
//             Calcium Avg % RDA
//           </th>
//           {/* Add more nutrients dynamically here as needed */}
//         </tr>
//       </thead>
//       <tbody>
//         {Object.values(usersData).map((user) =>
//           Object.keys(user.dates).map((date) => {
//             const dayData = user.dates[date]
//             return (
//               <tr key={`${user.id}-${date}`}>
//                 <td>{user.id}</td>
//                 <td>{date}</td>
//                 <td>{dayData.recordCount}</td>
//                 {/* <td>{dayData.calcium?.total || 0}</td> */}
//                 <td>{dayData.calcium?.totalPercentRDA?.toFixed(2) || 0}</td>
//               </tr>
//             )
//           })
//         )}
//       </tbody>
//     </table>
//   )
// }

function NutritionTable({ usersData, sortNutrition }) {
  return (
    <table>
      <thead>
        <tr>
          <th onClick={() => sortNutrition("id")}>User ID</th>
          <th onClick={() => sortNutrition("date")}>Date</th>
          <th onClick={() => sortNutrition("recordCount")}>Meals Logged</th>
          <th onClick={() => sortNutrition("five_a_day.total")}>
            Five a Day (Portions)
          </th>
          <th onClick={() => sortNutrition("protein.total")}>Protein (g)</th>
          <th onClick={() => sortNutrition("fibre.total")}>Fibre (g)</th>
          <th onClick={() => sortNutrition("calories.total")}>
            Calories (Kcals)
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(usersData).map((user, userIndex) =>
          Object.keys(user.dates).map((date, dateIndex) => {
            const dayData = user.dates[date]
            const isFirstDateForUser = dateIndex === 0

            return (
              <React.Fragment key={`${user.id}-${date}`}>
                {/* Marker Row at the start of each new user section */}
                {isFirstDateForUser && userIndex > 0 && (
                  <tr
                    className="user-separator"
                    style={{
                      backgroundColor: "#333333",
                    }}
                  >
                    <td
                      colSpan="7"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      User {user.id}
                    </td>
                  </tr>
                )}

                {/* Data Row */}
                <tr>
                  <td>{user.id}</td>
                  <td>{date}</td>
                  <td>{dayData.recordCount}</td>
                  <td>{dayData.five_a_day?.total || 0}</td>
                  <td>{dayData.protein?.total || 0}</td>
                  <td>{dayData.fibre?.total || 0}</td>
                  <td>{dayData.calories?.total || 0}</td>
                </tr>
              </React.Fragment>
            )
          })
        )}
      </tbody>
    </table>
  )
}

function UserMealStats() {
  const { currentUser } = useAuth()
  const [meals, setMeals] = useState([])
  const [sortedField, setSortedField] = useState(null)
  const [sortDirection, setSortDirection] = useState("asc")
  const [displayedMeals, setDisplayedMeals] = useState({})

  function calculateTotalKcals(ingredients) {
    let totalKcals = 0

    console.log("Ingredients:", ingredients)

    Object.values(ingredients).forEach((ingredient) => {
      const quantity = ingredient.quantity
      const kcalsPer100g = ingredient.nutrition?.Kcals

      // Calculate the kcal for the specific quantity and add it to totalKcals
      const kcalsForIngredient = (kcalsPer100g / 100) * quantity
      totalKcals += kcalsForIngredient
    })

    console.log("Total Kcals for this record:", totalKcals)

    return totalKcals
  }

  function processUserNutritionData(records) {
    const userNutritionData = {}

    records.forEach((record) => {
      const userId = record.syft_thread_idusers
      const date = new Date(record.syft_thread_timestamp)
        .toISOString()
        .split("T")[0]

      const Kcals = calculateTotalKcals(JSON.parse(record.nutritionData))

      let badgeData
      try {
        badgeData = JSON.parse(record.badge_league_tables)
      } catch (e) {
        console.error("Invalid JSON in badge_league_tables:", e)
        return
      }
      if (!badgeData || typeof badgeData !== "object") return

      // Initialize user structure if it doesn't exist
      if (!userNutritionData[userId]) {
        userNutritionData[userId] = { id: userId, dates: {} }
      }

      // Initialize daily data structure for this date if it doesn't exist
      if (!userNutritionData[userId].dates[date]) {
        userNutritionData[userId].dates[date] = {
          recordCount: 0,
          calories: { total: 0 },
        }
      }

      // if (userId === 16 && date === "2024-10-30") {
      //   console.log("New record")
      // }

      // Increment record count for each log entry within the same day
      userNutritionData[userId].dates[date].recordCount += 1
      userNutritionData[userId].dates[date].calories.total += Kcals

      // Process each nutrient in badgeData and sum values for the day
      Object.keys(badgeData).forEach((nutrient) => {
        const nutrientData = badgeData[nutrient]

        // Initialize the nutrient data if it doesn't exist for the day
        if (!userNutritionData[userId].dates[date][nutrient]) {
          userNutritionData[userId].dates[date][nutrient] = {
            total: 0,
            totalPercentRDA: 0,
            unit: nutrientData.Unit_toDisplay || "",
          }
        }

        // Add current nutrient totals from this record to the running total for the day
        userNutritionData[userId].dates[date][nutrient].total +=
          nutrientData.total_value_todisplay || 0
        userNutritionData[userId].dates[date][nutrient].totalPercentRDA +=
          nutrientData.percent_RDA || 0
      })
    })

    // Calculate average percent RDA per day for each nutrient after summing
    // Object.values(userNutritionData).forEach((user) => {
    //   Object.values(user.nutrition).forEach((dayData) => {
    //     Object.keys(dayData).forEach((key) => {
    //       if (key !== "recordCount") {
    //         const nutrient = dayData[key]
    //         nutrient.averagePercentRDA =
    //           nutrient.totalPercentRDA / dayData.recordCount
    //       }
    //     })
    //   })
    // })

    // Log for debugging to check if totals are correctly summed for each day
    console.log(userNutritionData)

    return userNutritionData
  }

  const fetchStats = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/user-meal-stats/2")
      .then((response) => {
        setMeals(response.data)
      })
      .catch((error) => {
        console.error("Error fetching conditions:", error)
      })
  }

  useEffect(() => {
    fetchStats()
  }, [])

  useEffect(() => {
    if (meals.length > 0) {
      const userData = processUserNutritionData(meals)
      setDisplayedMeals(userData)
      console.log(JSON.stringify(userData[19]))
    }
  }, [meals])

  const sortNutrition = (field) => {
    const newSortDirection =
      sortedField === field && sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(newSortDirection)
    setSortedField(field)

    const sortedData = { ...displayedMeals }
    Object.values(sortedData).forEach((user) => {
      user.nutrition = Object.fromEntries(
        Object.entries(user.nutrition).sort(([dateA, a], [dateB, b]) => {
          const aField = field.split(".").reduce((o, key) => o?.[key], a)
          const bField = field.split(".").reduce((o, key) => o?.[key], b)
          if (aField < bField) return newSortDirection === "asc" ? -1 : 1
          if (aField > bField) return newSortDirection === "asc" ? 1 : -1
          return 0
        })
      )
    })
    setDisplayedMeals(sortedData)
  }

  const exportToExcel = () => {
    // Prepare data for export
    const exportData = []

    Object.values(displayedMeals).forEach((user) => {
      Object.keys(user.dates).forEach((date) => {
        const dayData = user.dates[date]

        exportData.push({
          "User ID": user.id,
          Date: date,
          "Meals Logged": dayData.recordCount,
          "Five a Day (Portions)": dayData.five_a_day?.total || 0,
          "Protein (g)": dayData.protein?.total || 0,
          "Fibre (g)": dayData.fibre?.total || 0,
          "Calories (Kcals)": dayData.calories?.total || 0,
        })
      })
    })

    // Convert data to worksheet and create workbook
    const worksheet = XLSX.utils.json_to_sheet(exportData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Nutrition Data")

    // Export to Excel file
    XLSX.writeFile(workbook, "NutritionData.xlsx")
  }

  return (
    <div>
      <button onClick={exportToExcel}>Export to Excel</button>
      <div className="container">
        <div className="column">
          {Object.keys(displayedMeals).length === 0 ? (
            <p>Loading data...</p>
          ) : (
            <div className="requestContainer">
              <NutritionTable
                usersData={displayedMeals}
                sortNutrition={sortNutrition}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserMealStats
