import React from "react"
import FeedbackForm from "./components/FeedbackForm"

function Feedback() {
  return (
    <div>
      <h2>Feature Requests</h2>
      <p>
        Anything sent here will be logged and added to the build roadmap if it's
        achievable.
      </p>
      <FeedbackForm />
    </div>
  )
}

export default Feedback
