// authContext.js
import React, { createContext, useContext, useState } from "react"
import CryptoJS from "crypto-js"
// import { Route } from "react-router-dom"

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    // Check for saved user data on initial load
    const savedUser = localStorage.getItem("currentUser")
    return savedUser ? JSON.parse(savedUser) : null
  })

  async function login(username, password) {
    // Hash the password using SHA-256
    const hashedPassword = CryptoJS.SHA256(password).toString()
    // console.log("Hashed password:", hashedPassword)

    try {
      // Assuming you have an API endpoint that verifies user credentials
      const response = await fetch("https://api.syfthealth.app/sandbox/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: hashedPassword,
        }),
      })

      const data = await response.json()
      console.log(data)

      // Check if portal_users_id is present in the response to determine success
      if (data.portal_users_id) {
        console.log("Login successful!")
        const user = {
          id: data.portal_users_id,
          email: data.portal_users_email,
          username: data.portal_users_username,
          access: data.portal_users_access,
          // Add more fields from the response as needed
        }

        let route = "/"
        if (parseInt(data.portal_users_access, 10) >= 2) {
          route = "/demo"
        }

        setCurrentUser(user)
        localStorage.setItem("currentUser", JSON.stringify(user))
        return { success: true, route: route }
      } else {
        return { success: false, error: "Invalid username or password." } // Adjusted the error message
      }
    } catch (error) {
      console.error("Error during the login process:", error)
      return { success: false, error: "Something went wrong!" }
    }
  }

  function logout() {
    const confirmation = window.confirm("Are you sure you want to logout?")
    if (confirmation) {
      setCurrentUser(null)
      localStorage.removeItem("currentUser") // Remove user data from localStorage
    }
  }

  const value = {
    currentUser,
    login,
    logout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
