//Synfony.js
import React, { useState, useEffect } from "react"
import { useAuth } from "../authContext"
import { useLocation } from "react-router-dom"
import NutritionTable from "./NutritionTable"

function Synfony() {
  const { currentUser } = useAuth()
  const [thePrompt, setThePrompt] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [responses, setResponses] = useState([]) // To store server responses
  const location = useLocation()
  const [socket, setSocket] = useState(null) // WebSocket state
  const [mealData, setMealData] = useState(null) // Meal data state
  const [userData, setUserData] = useState(null) // Meal data state
  const [response, setResponse] = useState("") // To store server responses
  const [messages, setMessages] = useState([]) // To store the conversation
  const [actions, setActions] = useState({
    logMeal: false,
    adjustMeal: false,
    userData: false,
    conversation: false,
  }) // Actions state

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket("wss://api.syfthealth.app/synfony/")
    ws.onopen = () => console.log("Connected to the WebSocket")
    ws.onmessage = (event) => {
      try {
        // Try parsing the message as JSON
        const jsonData = JSON.parse(event.data)
        console.log(event.data)
        // Check if it has specific keys (like "mealData")
        if (jsonData.mealData) {
          // Add the data to the appropriate state for processing JSON
          console.log(jsonData.mealData)
          setMealData(jsonData.mealData) // assuming you have a state setter called setMealData
          setResponse(jsonData.response)
          setIsSending(false)
          setMessages([
            ...messages,
            {
              text: jsonData.response,
              sender: "William Shakespeare",
              type: "bot",
              image:
                "https://images.syfthealth.app/personalityimages/2023-08-07T14-07-57.937ZWS.png",
            },
          ])
          setMessages([
            ...messages,
            {
              text: jsonData.response,
              sender: "Syft",
              type: "syftbot",
              image:
                "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
            },
          ])
        } else if (jsonData.logMeal) {
          console.log(jsonData)
          setActions(jsonData)
        } else if (jsonData.userData) {
          console.log(jsonData.userData)
          setUserData(jsonData.userData)
        } else if (jsonData.response) {
          setResponse(jsonData.response)
          setMessages([
            ...messages,
            {
              text: jsonData.response,
              sender: "Syft",
              type: "syftbot",
              image:
                "https://images.syfthealth.app/personalityimages/2023-08-11T11-20-19.440Zandroid-chrome-512x512.png",
            },
          ])
        } else {
          setResponses((prevResponses) => [...prevResponses, event.data])
        }
        setIsSending(false)
      } catch (error) {
        // If there's an error in parsing, it's a plain string
        setResponses((prevResponses) => [...prevResponses, event.data])
        // setResponses([event.data])
      }
    }
    ws.onclose = () => {
      console.log("Disconnected from the WebSocket")
    }
    setSocket(ws)

    // Cleanup WebSocket connection
    return () => {
      ws.close()
    }
  }, [])

  const handleSearch = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ type: "mealSearch", query: thePrompt }))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (socket && socket.readyState === WebSocket.OPEN) {
      setResponses([])
      setMessages([
        {
          text: thePrompt,
          sender: "Name",
          type: "user",
          image: "",
        },
      ])
      setMealData(null)
      socket.send(thePrompt)
      setIsSending(true)
    }
  }

  const formStyles = {}

  const inputStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }
  const promptStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#eee" : "rgba(0,0,0,0.3)", // Change color when sending
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer", // Change cursor when sending
  }

  const leftColumn = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: "1", // left column will be 200px wide
    // flexBasis: "200px", // IE 10-11 fallback
    // width: "200px", // fixed width for left column
  }

  const rightColumn = {
    padding: "0 0 0 20px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: 1, // make right column take up the remaining space
  }

  const containerStyles = {
    fontSize: "12px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    width: "100%", // full width
    display: "flex", // set display to flex
    flexDirection: "row", // columns next to each other
    "@media (maxWidth: 768px)": {
      // media query for viewports less than 768px wide
      flexDirection: "column", // stack columns on top of each other
    },
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div style={containerStyles} className="input-container">
          <div>
            <label>
              Message
              <textarea
                value={thePrompt}
                onChange={(e) => setThePrompt(e.target.value)}
                style={inputStyles}
              />
            </label>
          </div>

          <div>
            <button type="submit" style={buttonStyles} disabled={isSending}>
              Send
            </button>
            <button type="button" style={buttonStyles} onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      </form>
      <div style={containerStyles}>
        <div style={leftColumn}>
          <h4>Actions:</h4>
          {actions && (
            <div>
              {actions.logMeal && (
                <div>Meal logged: {actions.logMeal.toString()}</div>
              )}
              {actions.adjustMeal && (
                <div>Meal adjusted: {actions.adjustMeal.toString()}</div>
              )}
              {actions.userData && (
                <div>User data: {actions.userData.toString()}</div>
              )}
              {actions.conversation && (
                <div>Conversation: {actions.conversation.toString()}</div>
              )}
            </div>
          )}

          <h4>Responses:</h4>
          {responses.map((response, index) => (
            <div key={index}>{response}</div>
          ))}
        </div>
        <div style={rightColumn}>
          {response && (
            <div>
              <h4>Response</h4>
              {response}
            </div>
          )}
          {userData && (
            <div>
              <h4>Profile Updates</h4>
              {JSON.stringify(userData, null, 2)}
            </div>
          )}
          {mealData && <NutritionTable mealData={mealData} />}
        </div>
      </div>
    </div>
  )
}

export default Synfony
