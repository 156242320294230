import React, { useState, useRef } from "react"
import axios from "axios"

function FileUploadPage() {
  const [file, setFile] = useState(null)
  const fileInputRef = useRef()

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleUpload = async () => {
    // Check if a file is selected and is a CSV
    if (file && file.name.endsWith(".csv")) {
      const formData = new FormData()
      formData.append("file", file)

      try {
        const response = await axios.post(
          "https://api.syfthealth.app/sandbox/uploadcsv",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        console.log("File uploaded successfully", response.data)

        // Reset the file input and state on success
        fileInputRef.current.value = "" // Reset the input
        setFile(null) // Reset the state
      } catch (error) {
        console.error("Error uploading file", error)
      }
    } else {
      // Alert or log if no file is selected or if it's not a CSV file
      console.error("Please select a CSV file to upload.")
    }
  }

  return (
    <div>
      <input type="file" onChange={handleFileChange} ref={fileInputRef} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  )
}

export default FileUploadPage
