import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./Reset.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import $ from "jquery"

const root = ReactDOM.createRoot(document.getElementById("root"))

window.addEventListener("resize", function () {
  var viewportHeight = window.innerHeight
  // var viewportHeight = window.visualViewport.height
  // Adjust your layout or scroll position based on the new height
  // For example:
  document.documentElement.style.setProperty("--vh", `${viewportHeight}px`)
  // const appElement = document.querySelector(".App")
  // if (appElement) {
  //   appElement.style.height = `${viewportHeight}px`
  // }
})

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js").then(
      (registration) => {
        console.log("ServiceWorker registered with scope: ", registration.scope)

        // Check for updates
        registration.update()

        // Add an event listener to track updates
        registration.onupdatefound = () => {
          const installingWorker = registration.installing

          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // New content is available; prompt the user to refresh
                console.log("This app has been updated")
                // Here, you can display a notification or alert to the user
                // asking them to refresh to see the new version.
              } else {
                // Content is cached; everything is fine
                console.log("Content is now available offline.")
              }
            }
          }
        }
      },
      (err) => {
        console.log("ServiceWorker registration failed: ", err)
      }
    )
  })
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
