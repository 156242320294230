//Synfony.js
import React from "react"
import Synfony from "./components/Synfony"

function SynfonyAI() {
  return (
    <div>
      <h2>Syft Conductor</h2>
      <Synfony />
    </div>
  )
}

export default SynfonyAI
