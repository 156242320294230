//ChangePassword.js
import React from "react"
import ChangePasswordForm from "./components/ChangePasswordForm"

function Requests() {
  return (
    <div>
      <h2>Change Password</h2>
      <ChangePasswordForm />
    </div>
  )
}

export default Requests
