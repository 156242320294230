import React, { useState } from "react"
import { useAuth } from "../authContext"
import CryptoJS from "crypto-js"

function ChangePassword() {
  const { currentUser } = useAuth()
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [apiResponse, setApiResponse] = useState("")
  const [isSending, setIsSending] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (newPassword !== confirmNewPassword) {
      setApiResponse("The new passwords do not match.")
      return
    }

    const hashedCurrentPassword = CryptoJS.SHA256(currentPassword).toString()
    const hashedNewPassword = CryptoJS.SHA256(newPassword).toString()

    setIsSending(true)
    const formData = {
      userId: currentUser.id, // Pass user's ID
      currentPassword: hashedCurrentPassword,
      newPassword: hashedNewPassword,
    }

    try {
      // Adjust this to point to the correct API endpoint for changing password
      const response = await fetch(
        "https://api.syfthealth.app/sandbox/changePassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
      const data = await response.text()
      console.log(data)
      setApiResponse(data)
    } catch (error) {
      console.error("There was an error submitting the form:", error)
    }
    setIsSending(false)
  }

  // Styles
  const formStyles = {
    // boxSizing: "border-box",
    // padding: "20px",
    // borderRadius: "5px",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    // margin: "40px auto",
  }

  const inputStyles = {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#eee" : "rgba(0,0,0,0.3)", // Change color when sending
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer", // Change cursor when sending
  }

  const labelStyles = {
    display: "block",
    marginBottom: "5px",
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div>
          {/* Display username as plain text */}
          <div style={{ marginBottom: "20px" }}>{currentUser.email}</div>
        </div>
        <div>
          <label>
            Current Password:
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              style={inputStyles}
              required
            />
          </label>
        </div>
        <div>
          <label>
            New Password:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={inputStyles}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Confirm New Password:
            <input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              style={inputStyles}
              required
            />
          </label>
        </div>
        <div>
          <button type="submit" style={buttonStyles} disabled={isSending}>
            Change Password
          </button>
        </div>
      </form>
      <div>{apiResponse}</div>
    </div>
  )
}

export default ChangePassword
