import React from "react"
import RequestForm from "./components/RequestForm"

function Requests() {
  return (
    <div>
      <h2>Feature Requests</h2>
      <p>
        Anything sent here will be logged and added to the build roadmap if it's
        achievable.
      </p>
      <RequestForm />
    </div>
  )
}

export default Requests
