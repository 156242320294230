import React from "react"
import RequestForm from "./components/PersonalitiesForm"

function Personalities() {
  return (
    <div>
      <h2>Tone Personalities</h2>
      <p>
        Anything sent here will be logged and added to the build roadmap if it's
        achievable.
      </p>
      <RequestForm />
    </div>
  )
}

export default Personalities
