import React from "react"
import UserStats from "./components/UserStats"

function Users() {
  return (
    <div>
      <h2>User Stats</h2>
      <UserStats />
    </div>
  )
}

export default Users
