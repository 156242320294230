import React from "react"
import EthicsForm from "./components/EthicsForm"

function Ethics() {
  return (
    <div>
      <h2>Ethics Prompt</h2>

      <p>
        <strong>Available Tags</strong>
      </p>
      <p>
        Tone of voice: &lt;&lt;PERSONALITY&gt;&gt;
        <br />
        User message: &lt;&lt;PROMPT&gt;&gt;
        <br />
        User classification: &lt;&lt;CONDITION&gt;&gt;
        <br />
        User goal: &lt;&lt;GOAL&gt;&gt;
        <br />
        AI Response: &lt;&lt;RESPONSE&gt;&gt;
      </p>
      <EthicsForm />
    </div>
  )
}

export default Ethics
