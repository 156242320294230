import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { useAuth } from "../authContext"
import "./PersonalitiesForm.css"

function PersonalitiesForm() {
  const { currentUser } = useAuth()
  const [personalities, setPersonalities] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const fileInputRef = useRef(null)

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    systemPrompt: "",
    userPrompt: "",
    image: "",
    json: "",
    enabled: 1,
  })

  useEffect(() => {
    fetchPersonalities()
  }, [])

  const fetchPersonalities = () => {
    axios
      .get("https://api.syfthealth.app/sandbox/syft-personalities")
      .then((response) => {
        setPersonalities(response.data)
      })
      .catch((error) => {
        console.error("Error fetching personalities:", error)
      })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    if (name === "image") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: event.target.files[0],
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData()

    for (let key in formData) {
      if (
        key !== "image" ||
        (key === "image" && typeof formData[key] === "object")
      ) {
        data.append(key, formData[key])
      }
    }

    const axiosConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }

    if (isUpdating) {
      handleSaveUpdate(formData.id, data, axiosConfig)
    } else {
      axios
        .post(
          "https://api.syfthealth.app/sandbox/syft-personalities",
          data,
          axiosConfig
        )
        .then(() => {
          fetchPersonalities()
          setFormData({
            name: "",
            description: "",
            systemPrompt: "",
            userPrompt: "",
            image: "",
            json: "",
            enabled: 1,
          })
        })
        .catch((error) => {
          console.error("Error adding new personality:", error)
        })
      fileInputRef.current.value = ""
    }
  }

  const handleUpdate = (personalityId) => {
    const personality = personalities.find(
      (p) => p.syft_personalities_id === personalityId
    )

    if (personality) {
      setFormData({
        id: personalityId,
        name: personality.syft_personalities_name || "", // replace null with empty string
        description: personality.syft_personalities_description || "",
        systemPrompt: personality.syft_personalities_system_prompt || "",
        userPrompt: personality.syft_personalities_user_prompt || "",
        image: personality.syft_personalities_image || "",
        json: personality.syft_personalities_json || "",
        enabled:
          personality.syft_personalities_enabled !== null
            ? parseInt(personality.syft_personalities_enabled, 10)
            : 1,
      })
    }
    setIsUpdating(true)
  }

  const handleSaveUpdate = (personalityId, data, axiosConfig) => {
    axios
      .put(
        `https://api.syfthealth.app/sandbox/syft-personalities/${personalityId}`,
        data,
        axiosConfig
      )
      .then(() => {
        fetchPersonalities()
        setFormData({
          name: "",
          description: "",
          systemPrompt: "",
          userPrompt: "",
          image: "",
          json: "",
          enabled: 1,
        })
      })
      .catch((error) => {
        console.error("Error updating personality:", error)
      })
    fileInputRef.current.value = ""

    setIsUpdating(false)
  }

  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Name"
        />
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          className="description"
        />
        <textarea
          name="systemPrompt"
          value={formData.systemPrompt}
          onChange={handleInputChange}
          placeholder="System Prompt"
          className="prompt"
        />
        <textarea
          name="userPrompt"
          value={formData.userPrompt}
          onChange={handleInputChange}
          placeholder="User Prompt"
          className="prompt"
        />
        <input
          type="file"
          name="image"
          ref={fileInputRef}
          onChange={handleFileChange}
          placeholder="Upload Image"
        />

        <textarea
          name="json"
          value={formData.json}
          onChange={handleInputChange}
          placeholder="JSON Data"
          className="json"
        />
        <select
          name="enabled"
          value={formData.enabled}
          onChange={handleInputChange}
          className="dropdown"
        >
          <option value={1}>Enabled</option>
          <option value={0}>Disabled</option>
        </select>
        <button type="submit">
          {isUpdating ? "Update Personality" : "Add Personality"}
        </button>
      </form>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <div className="personalityContainer">
        {personalities.map((personality) => (
          <div
            key={personality.syft_personalities_id}
            className="personalityRow"
          >
            <img
              src={
                "https://images.syfthealth.app/personalityimages/" +
                personality.syft_personalities_image
              }
              alt={personality.syft_personalities_name}
              className="personalityImage"
            />

            <div className="personalityItem">
              {personality.syft_personalities_name}
            </div>
            <div className="personalityItem">
              {personality.syft_personalities_description}
            </div>
            <button
              onClick={() => handleUpdate(personality.syft_personalities_id)}
            >
              Edit
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PersonalitiesForm
