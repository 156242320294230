import React, { useState, useEffect } from "react"

function NutritionTable({ mealData }) {
  const [adjustedData, setAdjustedData] = useState([])
  const [totals, setTotals] = useState({})

  useEffect(() => {
    // Adjust nutrient values based on quantity
    const newAdjustedData = Object.entries(mealData).map(
      ([ingredient, data]) => {
        const adjustedNutrition = {}
        for (let [key, value] of Object.entries(data.nutrition)) {
          adjustedNutrition[key] = (value * data.quantity) / 100 // assuming nutrition values are per 100g
        }
        return {
          ingredient,
          quantity: data.quantity,
          ...adjustedNutrition,
        }
      }
    )

    // Calculate totals
    const newTotals = newAdjustedData.reduce((acc, item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "ingredient") {
          acc[key] = (acc[key] || 0) + value
        }
      }
      return acc
    }, {})

    setAdjustedData(newAdjustedData)
    setTotals(newTotals)
  }, [mealData])

  return (
    <div>
      <h4>Nutrition</h4>

      <table>
        <thead>
          <tr>
            <th>Ingredient</th>
            <th>Qty (g)</th>
            <th>Kcals</th>
            <th>Fibre</th>
            <th>Carbs</th>
            {/* <th>Sugars</th> */}
            <th>Protein</th>
            {/* <th>Fat</th>
            <th>Salt</th> */}
          </tr>
        </thead>
        <tbody>
          {adjustedData.map((item) => (
            <tr key={item.ingredient}>
              <td>{item.ingredient}</td>
              <td>{item.quantity}</td>
              <td>{item.Kcals ? item.Kcals.toFixed(0) : "-"}</td>
              <td>{item.Fibre ? item.Fibre.toFixed(0) : "-"}</td>
              <td>{item.Carbs ? item.Carbs.toFixed(0) : "-"}</td>
              {/* <td>{item.Sugars ? item.Sugars.toFixed(0) : "-"}</td> */}
              <td>{item.Protein ? item.Protein.toFixed(0) : "-"}</td>
              {/* <td>{item.Fat ? item.Fat.toFixed(0) : "-"}</td>
              <td>{item.Salt ? item.Salt.toFixed(0) : "-"}</td> */}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Totals:</td>
            <td>{totals.quantity ? totals.quantity.toFixed(0) : "-"}</td>
            <td>{totals.Kcals ? totals.Kcals.toFixed(0) : "-"}</td>
            <td>{totals.Fibre ? totals.Fibre.toFixed(0) : "-"}</td>
            <td>{totals.Carbs ? totals.Carbs.toFixed(0) : "-"}</td>
            {/* <td>{totals.Sugars ? totals.Sugars.toFixed(0) : "-"}</td> */}
            <td>{totals.Protein ? totals.Protein.toFixed(0) : "-"}</td>
            {/* <td>{totals.Fat ? totals.Fat.toFixed(0) : "-"}</td>
            <td>{totals.Salt ? totals.Salt.toFixed(0) : "-"}</td> */}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default NutritionTable
