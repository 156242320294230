import React from "react"
import OpenAIForm from "./components/OpenAIForm"

function OpenAI() {
  return (
    <div>
      <h2>OpenAI Models</h2>
      <p>
        This interface connects directly to the OpenAi API. It doesn't
        automatically provide context to the model; you must supply all the
        information you want it to consider.
      </p>
      <p>
        By adding <strong>&lt;&lt;MEAL&gt;&gt;</strong> to your input, the
        system will fetch a recipe name from the 235k recipes stored in the Syft
        database.
      </p>
      <p>
        The retrieval begins from the <strong>'Start Meal'</strong> position in
        the list and will cycle through for the number of times specified in{" "}
        <strong>'Number of Meals'</strong>
      </p>

      {/* <p>
        This is a direct interface to the OpenAi API. There is no prompt
        wrapping so the model has no context beyond what you supply.
        <br />
        Including <strong>&lt;&lt;MEAL&gt;&gt;</strong> in the prompt will pull
        a recipe name from the 235k recipes in the Syft databse.
      </p>
      <p>
        It will start from the <strong>Start Meal</strong> position in the list
        and loop through <strong>Number of Meals</strong> times.
      </p> */}
      <OpenAIForm />
    </div>
  )
}

export default OpenAI
