//App.js
import React from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"

import logo from "./logo-pink-white.svg"
import logoblue from "./logo-pink-blue.svg"
import "./App.css"

import Menu from "./Menu"
import { AuthProvider } from "./authContext"
import { useAuth } from "./authContext"

import Dashboard from "./Dashboard"
import OpenAI from "./OpenAI"
import Syft from "./Syft"
import Requests from "./Requests"
import Feedback from "./Feedback"
import NotFound from "./NotFound"
import Login from "./Login"
import SynfonyAI from "./SynfonyAI"
import Ethics from "./Ethics"
import Personalities from "./Personalities"
import UserItems from "./UserItems"
import Lab from "./Lab"
import MessagingInterface from "./Demo"
import Users from "./Users"
import UserMeals from "./UserMeals"
import ChangePassword from "./ChangePassword"
import CSVtoTable from "./CSVtoTable"
import Logs from "./Logs"
import PrivateRoute from "./PrivateRoute"
import { ACCESS_LEVELS } from "./AccessLevels"

import "./Messaging.css"

// import { useNavigate } from "react-router-dom"

const menuItems = [
  {
    id: 1,
    name: "Home",
    path: "/",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 2,
    name: "OpenAI",
    path: "/openai",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 3,
    name: "Syft",
    path: "/syft",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 4,
    name: "SynfonyAI",
    path: "/synfonyai",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 5,
    name: "Ethics Lab",
    path: "/ethics",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 6,
    name: "Tone Lab",
    path: "/lab",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 7,
    name: "Personalities",
    path: "/personalities",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 8,
    name: "User Items",
    path: "/UserItems",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 9,
    name: "CSV to table",
    path: "/csvtotable",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 10,
    name: "Feedback",
    path: "/feedback",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 11,
    name: "Requests",
    path: "/requests",
    allowedAccessLevels: [ACCESS_LEVELS.DEV],
  },
  {
    id: 12,
    name: "Log Viewer",
    path: "/logs",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 13,
    name: "Demo",
    path: "/demo",
    allowedAccessLevels: [
      ACCESS_LEVELS.DEV,
      ACCESS_LEVELS.ADMIN,
      ACCESS_LEVELS.INVESTOR,
      ACCESS_LEVELS.ADVISOR,
    ],
  },
  {
    id: 14,
    name: "Users",
    path: "/users",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  {
    id: 15,
    name: "UserMeals",
    path: "/usermeals",
    allowedAccessLevels: [ACCESS_LEVELS.DEV, ACCESS_LEVELS.ADMIN],
  },
  // Add more items as needed...
]

function getAccessLevelName(accessLevel) {
  switch (accessLevel) {
    case 0:
      return "Developer"
    case 1:
      return "Admin"
    case 2:
      return "Investor"
    case 3:
      return "Advisor"
    default:
      return "Unknown"
  }
}

function App() {
  const { currentUser, logout } = useAuth()

  const handleLogout = () => {
    logout()
  }

  const allowedMenuItems = currentUser
    ? menuItems.filter((item) =>
        item.allowedAccessLevels.includes(currentUser.access)
      )
    : []

  return (
    <Router>
      <div
        className={`App ${
          currentUser && currentUser.access > 1 ? "reset" : ""
        }`}
      >
        <div className="App-panel">
          <div className="App-header">
            <img
              src={currentUser && currentUser.access > 1 ? logoblue : logo}
              className="App-logo"
              alt="logo"
            />

            {currentUser && (
              <div className="Header-buttons">
                <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
                <Link to="/ChangePassword" className="settings-button">
                  {/* Replace with your cog icon */}
                  <span className="cog-icon">⚙️</span>
                </Link>
              </div>
            )}
          </div>
          <div className="App-body-panel">
            {currentUser && allowedMenuItems.length > 1 && (
              <div className="App-sidebar-panel">
                <Menu
                  items={allowedMenuItems}
                  currentUserAccessLevel={currentUser.access}
                />
              </div>
            )}
            <div
              className={`App-inner-panel ${!currentUser ? "full-width" : ""}`}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute
                      allowedAccessLevels={[
                        ACCESS_LEVELS.DEV,
                        ACCESS_LEVELS.ADMIN,
                        ACCESS_LEVELS.INVESTOR,
                        ACCESS_LEVELS.ADVISOR,
                      ]}
                    />
                  }
                >
                  <Route index element={<Dashboard />} />
                  <Route path="/openai" element={<OpenAI />} />
                  <Route path="/syft" element={<Syft />} />
                  <Route path="/synfonyai" element={<SynfonyAI />} />
                  <Route path="/lab" element={<Lab />} />
                  <Route path="/personalities" element={<Personalities />} />
                  <Route path="/ethics" element={<Ethics />} />
                  <Route path="/useritems" element={<UserItems />} />
                  <Route path="/csvtotable" element={<CSVtoTable />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/requests" element={<Requests />} />
                  <Route path="/logs" element={<Logs />} />
                  <Route path="/changepassword" element={<ChangePassword />} />
                  <Route path="/demo" element={<MessagingInterface />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/usermeals" element={<UserMeals />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  )
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  )
}

export default AppWrapper
